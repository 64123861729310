import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="email"
export default class extends Controller {
  connect() {
  }


  loadText() {
    console.log("loading text")
    var textChosen = document.getElementById('email_preset_text').value

    if (textChosen) {
      fetch("/preset_texts/" + textChosen + "/get_text", {
        headers: {
          Accept: "text/vnd.turbo-stream.html",
        },
      })
        .then(r => r.text())
        .then(html => Turbo.renderStreamMessage(html))
    }
  }

}
