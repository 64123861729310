import { Controller } from "@hotwired/stimulus"
import JustValidate from 'just-validate';

// Connects to data-controller="agreement"
export default class extends Controller {
  static targets = ["theForm"]

  connect() {
    console.log("Agreement loaded")
    this.validateAgreement()
  }

  validateAgreement() {
    (() => {
      var form = this.theFormTarget
      var lang = form.dataset.lang

      const validation = new JustValidate(form, {
        errorFieldCssClass: 'is-invalid',
        errorLabelStyle: {
          marginTop: '0.25rem',
          fontSize: '10px',
          color: '#dc3545',
        },
        focusInvalidField: true,
        lockForm: true,
      },
        [
          {
            key: 'required',
            dict: {
              en: 'This field is required',
              fr: 'Champ obligatoire',
            }
          }
        ]
      );

      validation.setCurrentLocale(lang)

      validation
      .addField('#agreement_contact_attributes_salutation', [
        {
          rule: 'required',
          errorMessage: 'required'
        }
      ])
      .addField('#agreement_contact_attributes_first_name', [
        {
          rule: 'required',
          errorMessage: 'required'
        }
      ])
      .addField('#agreement_contact_attributes_last_name', [
        {
          rule: 'required',
          errorMessage: 'required'
        }
      ])
      .addField('#agreement_contact_attributes_date_of_birth', [
        {
          rule: 'required',
          errorMessage: 'required'
        }
      ])
      .addField('#agreement_contact_attributes_sex', [
        {
          rule: 'required',
          errorMessage: 'required'
        }
      ])
      .addField('#agreement_contact_attributes_address_attributes_address', [
        {
          rule: 'required',
          errorMessage: 'required'
        }
      ])
      .addField('#agreement_contact_attributes_address_attributes_city', [
        {
          rule: 'required',
          errorMessage: 'required'
        }
      ])
      .addField('#agreement_contact_attributes_address_attributes_postcode', [
        {
          rule: 'required',
          errorMessage: 'required'
        }
      ])
      .addField('#agreement_contact_attributes_address_attributes_country', [
        {
          rule: 'required',
          errorMessage: 'required'
        }
      ])
      .addField('#agreement_contact_attributes_email_addresses_attributes_0_email_type', [
        {
          rule: 'required',
          errorMessage: 'required'
        }
      ])
      .addField('#agreement_contact_attributes_email_addresses_attributes_0_email_address', [
        {
          rule: 'required',
          errorMessage: 'required'
        }
      ])
      .addField('#agreement_contact_attributes_phone_numbers_attributes_0_home_work', [
        {
          rule: 'required',
          errorMessage: 'required'
        }
      ])
      .addField('#agreement_contact_attributes_phone_numbers_attributes_0_country', [
        {
          rule: 'required',
          errorMessage: 'required'
        }
      ])
      .addField('#agreement_contact_attributes_phone_numbers_attributes_0_number', [
        {
          rule: 'required',
          errorMessage: 'required'
        }
      ])
      .addField('#agreement_next_of_kin', [
        {
          rule: 'required',
          errorMessage: 'required'
        }
      ])
      .addField('#agreement_next_of_kin_relation', [
        {
          rule: 'required',
          errorMessage: 'required'
        }
      ])
      .addField('#agreement_next_of_kin_phone', [
        {
          rule: 'required',
          errorMessage: 'required'
        }
      ])
      .addField('#agreement_medical_conditions', [
        {
          rule: 'required',
          errorMessage: 'required'
        }
      ])
      .addField('#agreement_previous_experience', [
        {
          rule: 'required',
          errorMessage: 'required'
        }
      ])
      .addField('#agreement_i_agree', [
        {
          rule: 'required',
          errorMessage: 'required'
        }
      ])
      .onSuccess((event) => {
        console.log('Validation passes and form submitted', event);
        form.submit()
      })

    })();
  }
}