import { Controller } from "@hotwired/stimulus"
import ScrollTo from 'stimulus-scroll-to'

// Connects to data-controller="trip-course"
export default class extends Controller {
  static targets = ["dates"];

  connect() {
    console.log("trip courses loaded")
  }

  toggleBackground(e) {
    console.log(e.target.dataset)
    const target = e.target
    const colour = target.dataset.bgcolour
    target.classList.toggle(colour)
  }

  openDates() {
    console.log('opening dates panel')
    const dates = document.getElementById("dates_content")
    const anchor = document.getElementById("dates_anchor")
    anchor.scrollIntoView()
    dates.classList.remove("hidden")
    
  }
}
