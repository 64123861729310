import { Controller } from "@hotwired/stimulus"
import Glide, { Controls } from '@glidejs/glide/dist/glide.modular.esm'

export default class extends Controller {

  initialize() {
    var swiper = new Swiper(".mySwiper", {
      slidesPerView: 3,
      spaceBetween: 30,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });


    console.log("slider initialized")

    // var glide = new Glide('.glide', {
    //   type: 'carousel',
    //   focusAt: 'center',
    //   perView: 3
    // })

    // var glide = new Glide('.glide', {
    //   type: 'slider',
    //   // autoplay: 4000,
    //   animationDuration: 1000,
    //   gap: 40,
    //   // rewind: true,
    //   breakpoints: {
    //     4000: {
    //       perView: 4,
    //       gap: 40,
    //     },
    //     992: {
    //       perView: 2,
    //       gap: 40,
    //     },
    //     768: {
    //       perView: 1,
    //       gap: 30
    //     },
    //     576: {
    //       perView: 1,
    //       gap: 15
    //     }
    //   }
    // }).mount({ Controls })

  }

}