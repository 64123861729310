import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="phone-numbers"
export default class extends Controller {
  static targets = ["add", "template"]
  
  connect() {
  }

  add(event) {
    console.log("djfhd")
    event.preventDefault()
    var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.addTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove(event) {
    console.log('removing')
    event.preventDefault()
    let item = event.target.closest(".nested-fields")
    item.querySelector("input[name*='_destroy']").value = 1
    item.style.display = 'none'
  }
}
