import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="recaptcha"
export default class extends Controller {
  static targets = ["form"];

  connect() {
    console.log("recaptcha loaded")
  }

  onSubmit(token) {
    console.log("recaptcha submitted")
    console.log(token)
    // this.formTarget.submit();
  }

}
