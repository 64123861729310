import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

let debounce = require('lodash/debounce');
let throttle = require('lodash/throttle');

// Connects to data-controller="search_form"
export default class extends Controller {
  static targets = ["formholder", "form", "searchinput"]

  connect() {
    console.log("search controller loaded")

    this.date_range()

    document.addEventListener('keypress', (event) => {
      if (event.code === "Backquote" || event.code === "IntlBackslash") {
        event.preventDefault();
        this.showSearchModal()
      }
    }, false);
  }

  date_range() {
    mobiscroll.datepicker('.range_picker', {
      controls: ['calendar'],
      select: 'range',
      dateFormat: 'MMMM DD, YYYY', 
      touchUi: true,
      showWeekNumbers: false,
      rangeHighlight: true,
      showRangeLabels: true,
      rangeStartLabel: 'First day',
      rangeEndLabel: 'Last day',
      display: 'anchored',
      showOnClick: true,
      showOnFocus: true,
      firstDay: 1,
      onChange: function (event, inst) {
        var fieldId = inst._input.id;
        console.log(fieldId)
        var startDate = document.getElementById('q_' + fieldId + '_gteq')
        var endDate = document.getElementById('q_' + fieldId + '_lteq')

        startDate.value = event.value[0];
        endDate.value = event.value[1];
      }
    });
  }

  initialize() {
    this.submitSearch = debounce(this.submitSearch, 750).bind(this)
    this.meiliSubmitSearch = debounce(this.meiliSubmitSearch, 750).bind(this)
    console.log("initialized search")
  }

  resetSearch() {
    console.log("resetting form")
    const form = document.getElementById('search_form')
    form.reset() 

    const hiddenFields = document.getElementsByClassName('hidded')
    for (var i=0; i<hiddenFields.length; i++) {
      hiddenFields[i].value = null;
    }

    document.getElementById('resetField').value = false
    Rails.fire(form, 'submit');
  }

  downloadSearch() {
    var form = document.getElementById('search_form')
    var new_form = document.getElementById('download_form')

    console.log("download xls")
    Rails.fire(new_form, 'submit');
  }

  submitSearch() {
    var form = document.getElementById('search_form')
    // document.getElementById('resetField').value = true
    console.log("form fired")
    Rails.fire(form, 'submit');
  }

  meiliSubmitSearch() {
    var form = document.getElementById('meili_search_form')
    // document.getElementById('resetField').value = true
    console.log("form fired")
    Rails.fire(form, 'submit');
  }

  showSearchModal() {
    console.log("search modal triggered")
    this.formholderTarget.classList.toggle("hidden")
    console.log(this.searchinputTarget)
    this.searchinputTarget.focus()
    this.searchinputTarget.select()
  }

  showModal() {
    const form = document.getElementById('search_modal')
    console.log("form modal triggered")
    form.classList.remove("hidden")
    console.log("load datepicker")
  }

  hideForm() {
    const form = document.getElementById('search_modal')
    console.log("hiding form modal")
    form.classList.add("hidden")
    // document.getElementById('forms').innerHTML = "";
  }

}
