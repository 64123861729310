import { Controller } from "@hotwired/stimulus"
import intlTelInput from 'intl-tel-input';

// Connects to data-controller="phone"
export default class extends Controller {
  static targets = ["country"];

  connect() {
    const input = document.querySelector("#enquiry_phone");
    var country = this.countryTarget
    const iti = intlTelInput(input, {
      initialCountry: 'fr',
      preferredCountries: [],
        // any initialisation options go here
    });

    input.addEventListener('countrychange', function(e) {
      country.value = iti.getSelectedCountryData().iso2;
    });
  }
}
