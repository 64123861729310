import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="reset_form"
export default class extends Controller {
  connect() {
    console.log("reset form loaded")
  }

  reset() {
    this.element.reset()
  }
}
