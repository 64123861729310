import { Controller } from "@hotwired/stimulus"
import * as Masonry from "masonry-layout"

// Connects to data-controller="masonry"
export default class extends Controller {
  connect() {
    var grid = document.querySelector('.grid');
    var msnry = new Masonry( grid, {
      // options...
      itemSelector: '.page_block',
    });

  }
}
