import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mountain-hut-reservation"
export default class extends Controller {
  static targets = ["arrivalDate", "departureDate"]

  connect() {

    console.log("mount hut reservation loaded")

    var orderStartDate = document.getElementById('order_start_date').value
    var orderEndDate = document.getElementById('order_end_date').value

    var arrivalDate = this.arrivalDateTarget
    var departureDate = this.departureDateTarget

    mobiscroll.datepicker('#mountain_hut_reservation_dates', {
      controls: ['calendar'],
      select: 'range',
      dateFormat: 'MMMM DD, YYYY', 
      touchUi: true,
      showWeekNumbers: false,
      rangeHighlight: true,
      showRangeLabels: true,
      rangeStartLabel: 'First day',
      rangeEndLabel: 'Last day',
      display: 'anchored',
      showOnClick: true,
      showOnFocus: true,
      firstDay: 1,
      onInit: function (event, inst) {
        // inst.setVal([arrivalDate.value, departureDate.value]);
        console.log("arrivaldate " + arrivalDate.value)
        if (arrivalDate.value.length > 0) {
          inst.setVal([arrivalDate.value, departureDate.value]);
        } else {
          inst.setVal([orderStartDate, orderEndDate]);
        }
      },
      onChange: function (event, inst) {
        console.log(arrivalDate)
        arrivalDate.value = event.value[0]
        departureDate.value = event.value[1]
      }
    });
  }
}
