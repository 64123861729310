import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="notes"
export default class extends Controller {
  static values = { mhr: Number }

  connect() {
  }

  showForm(event) {
    console.log('show form')
    var mtr = event.currentTarget.dataset.value
    var formHolder = document.getElementById("form_" + mtr)
    formHolder.classList.toggle("hidden")
    formHolder.classList.toggle("animate__slideInDown")
  }
}
