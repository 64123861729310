import { Controller } from "@hotwired/stimulus"
import ReadSmore from 'read-smore'

// Connects to data-controller="main"
export default class extends Controller {
  connect() {
    console.log('main loaded')

    const readMores = document.querySelectorAll('.js-read-smore')
    ReadSmore(readMores).init()
  }
}
