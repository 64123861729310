import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dob"
export default class extends Controller {
  static targets = ["dob"]

  connect() {

    mobiscroll.datepicker(this.dobTarget, {
      controls: ['date'],
      dateFormat: 'DD/MM/YYYY',
      touchUi: true,
      showWeekNumbers: false,
      rangeHighlight: true,
      showRangeLabels: true,
      display: 'anchored',
      showOnClick: true,
      showOnFocus: true,
      firstDay: 1
    });

  }
}
