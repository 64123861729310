import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="transfers"
export default class extends Controller {

  connect() {
    console.log("transfer form loaded")

    var orderStartDate = document.getElementById('temp_start_date')
    var transferDateTime = document.getElementById('transfer_departure_arrival_time')

    mobiscroll.datepicker('#transfer_departure_arrival_time', {
      controls: ['calendar', 'time'],
      touchUi: true,
      showWeekNumbers: false,
      rangeHighlight: true,
      showRangeLabels: true,
      rangeStartLabel: 'Pick Up',
      rangeEndLabel: 'Drop Off',
      dateFormat: 'DDD, D MMM YYYY - ',
      timeFormat: 'HH:mm',
      stepMinute: 5,
      display: 'anchored',
      min: '#{Date.now}',
      firstDay: 1,
      onInit: function (event, inst) {
        if (!transferDateTime.value) {
          console.log("setting order dates as global")
          inst.setVal(orderStartDate.value);
        } else {
          console.log("existing transfer time: " + transferDateTime.value)
          inst.setVal(transferDateTime.value);
        }
      },
    });
  }
  //Sat, 22 Jan 2022 – 23:00
}
