import { Controller } from "@hotwired/stimulus"
import Swiper, { Autoplay } from 'swiper';

// Connects to data-controller="banners"
export default class extends Controller {
  connect() {
    console.log('Banners controller connected')

    var swiper = new Swiper(".banners", {
      modules: [Autoplay],
      slidesPerView: 1,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      // pagination: {
      //   el: ".swiper-pagination",
      //   clickable: true,
      // },
      // navigation: {
      //   nextEl: ".swiper-button-next",
      //   prevEl: ".swiper-button-prev",
      // }
    });
    console.log(swiper)
  }
}
