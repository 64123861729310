// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "trix"
import "@rails/actiontext"

const images = require.context('../images', true)

import "@hotwired/turbo-rails"
Turbo.session.drive = false

import * as mobiscroll from "@mobiscroll/javascript/dist/esm5/mobiscroll.javascript.min.js"
window.mobiscroll = mobiscroll

import { FocusedImage } from "image-focus"
window.FocusedImage = FocusedImage

import { Fancybox } from "@fancyapps/ui"
window.Fancybox = Fancybox;

import AOS from 'aos'
window.AOS = AOS

// import dropzone from "dropzone"
// window.dropzone = dropzone

import "controllers"
import "../stylesheets/application.sass"

Rails.start()
ActiveStorage.start()

