import { Controller } from "@hotwired/stimulus"
import Pristine from "pristinejs"
export default class extends Controller {

  connect() {
    console.log("accommodation booking loaded")

    var orderStartDate = document.getElementById('temp_start_date')
    var orderEndDate = document.getElementById('temp_end_date')

    var startDate = document.getElementById('accommodation_booking_start_date')
    var endDate = document.getElementById('accommodation_booking_end_date')

    mobiscroll.datepicker('.datepicker', {
      controls: ['calendar'],
      select: 'range',
      dateFormat: 'DD/MM/YYYY',
      touchUi: true,
      showWeekNumbers: false,
      rangeHighlight: true,
      showRangeLabels: true,
      rangeStartLabel: 'First day',
      rangeEndLabel: 'Last day',
      display: 'anchored',
      showOnClick: true,
      showOnFocus: true,
      firstDay: 1,
      onInit: function (event, inst) {
        if (!startDate.value) {
          console.log("setting order dates as global")
          inst.setVal([orderStartDate.value, orderEndDate.value]);
          startDate.value = orderStartDate.value
          endDate.value = orderEndDate.value
        } else {
          inst.setVal([startDate.value, endDate.value]);
        }
      },
      onChange: function (event, inst) {
        startDate.value = event.value[0]
        endDate.value = event.value[1]
      }
    });

  }

}
