import { Controller } from "@hotwired/stimulus"
// Connects to data-controller="form"
export default class extends Controller {
  

  connect() {
    console.log("form loaded")
  }

  showForm() {
    const form = document.getElementById('form_modal')
    console.log("form modal triggered")
    form.classList.remove("hidden")
    console.log("load datepicker")
  }

  hideForm() {
    const form = document.getElementById('form_modal')
    console.log("hiding form modal")
    form.classList.add("hidden")
    document.getElementById('forms').innerHTML = "";
  }

  load_datepicker() {
    mobiscroll.datepicker('.datepicker');
  }

}
