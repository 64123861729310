import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="focus"
export default class extends Controller {
  connect() {
    console.log("Focus controller connected")

    var vanillafocus_demo = new vanillafocus({
      selector: ".vf-container",
      reCalcOnWindowResize: true
    });
    // const img = document.querySelector('.focused-image')
    // const focusedImage = new FocusedImage(img)
  }
}
