import { Controller } from "@hotwired/stimulus"
import ScrollTo from 'stimulus-scroll-to'


// Connects to data-controller="navigation-mobile"
export default class extends Controller {
  static targets = ["navbar", "navpanel"]

  connect() {
    console.log('navigation loaded')
  }

  showMenu() {
    console.log(this)
    this.navpanelTarget.classList.toggle('hidden')

    if (this.navpanelTarget.classList.contains('hidden')) {
      this.removeSubMenus()
    }
  }

  showSubMenu(e) {
    console.log(e)
    var menu = document.getElementById(e.target.dataset.name)
    menu.classList.toggle('hidden')

    this.navpanelTarget.scroll(0, e.target.offsetTop - this.navbarTarget.offsetHeight - 100)
  }

  removeSubMenus() {
    var subMenus = Array.from(document.getElementsByClassName('sub_menu'))

    subMenus.forEach(subMenu => {
      if (!subMenu.classList.contains('hidden')) {
        subMenu.classList.add('hidden')
      }
    })
  }

}
