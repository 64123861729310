import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="read-more"
export default class extends Controller {
  static targets = ["content", "button", "fade"]

  connect(r) {
    console.log('read more connected')
  }

  readMore() {
    console.log('read more clicked')
    this.contentTarget.classList.remove('max-h-screen3/4')
    this.buttonTarget.classList.add('hidden')
    this.fadeTarget.classList.add('hidden')
  }
}
