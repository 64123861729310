import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="meeting"
export default class extends Controller {
  connect() {

    mobiscroll.datepicker('#meeting_when', {
      controls: ['calendar', 'timegrid'],
      touchUi: true,
      showWeekNumbers: false,
      rangeHighlight: true,
      showRangeLabels: true,
      rangeStartLabel: 'Pick Up',
      rangeEndLabel: 'Drop Off',
      dateFormat: 'DDD, D MMM YYYY - ',
      timeFormat: 'HH:mm',
      stepMinute: 15,
      display: 'anchored',
      firstDay: 1
    });

  }
}
