import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="guides"
export default class extends Controller {
  connect() {
    console.log('guides loaded')
    this.schedule()
  }

  schedule() {
    const calendarObject = document.getElementById('schedule')

    mobiscroll.setOptions({
      locale: mobiscroll.localeEnGB
    });

    var calendar = mobiscroll.eventcalendar('#schedule', {
      view: {
        timeline: { type: 'month' },
        rowHeight: '50px'
      },
      allDayText: null,
      onPageLoading: function (event, inst) {
        inst._events = []

        var year = event.firstDay.getFullYear(),
          month = event.firstDay.getMonth() + 1,
          day = event.firstDay.getDate();

        loadData(year, month, day, calendarObject.dataset.overlap, inst);
      },
      onEventClick: function (data, inst) {
        window.location.href = data.event.url
      },
    });

    function loadData(year, month, day, period, inst) {

      var dateSent = year + '-' + month + '-' + day
      mobiscroll.util.http.getJson('/guides/chamex_schedule.json?d=' + dateSent + '&p=' + period, function (inst) {
        console.log(inst)
        calendar.setOptions({ data: inst.data, resources: inst.resources });
      }, 'json');
    }
  }

  guideCal() {
    mobiscroll.setOptions({
      theme: 'ios',
      themeVariant: 'light'
    });
  }

}
