import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="job-offer"
export default class extends Controller {
  static values = { startDate: String, endDate: String }
  static targets = ["startDate", "endDate"]

  connect() {
    console.log("job offers loaded")

    var startDate = this.startDateTarget
    var endDate = this.endDateTarget

    mobiscroll.datepicker('#job_offer_dates', {
      controls: ['calendar'],
      select: 'range',
      dateFormat: 'MMMM DD, YYYY', 
      touchUi: true,
      showWeekNumbers: false,
      rangeHighlight: true,
      showRangeLabels: true,
      rangeStartLabel: 'First day',
      rangeEndLabel: 'Last day',
      display: 'anchored',
      showOnClick: true,
      showOnFocus: true,
      firstDay: 1,
      onInit: function (event, inst) {
        if (!startDate) {
          console.log("setting order dates as global")
          inst.setVal([startDate.value, endDate.value]);
        } else {
          inst.setVal([startDate.value, endDate.value]);
        }
      },
      onChange: function (event, inst) {
        console.log(startDate)
        startDate.value = event.value[0]
        endDate.value = event.value[1]
      }
    });

    console.log(startDate)

  }
}
