import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="lift-passes"
export default class extends Controller {
  connect() {
    console.log("lift passes connected")

    mobiscroll.datepicker('#lift_pass_start_date', {
      controls: ['calendar'],
      dateFormat: 'DD/MM/YYYY',
      touchUi: true,
      showWeekNumbers: false,
      rangeHighlight: true,
      showRangeLabels: true,
      display: 'anchored',
      min: '#{Date.now}',
      showOnClick: true,
      showOnFocus: true,
      firstDay: 1
    });
  }
}
