import { Controller } from "@hotwired/stimulus"
import Swiper, { Navigation, Pagination } from 'swiper';

// Connects to data-controller="swiper"
export default class extends Controller {
  static targets = [ "grid", "auto" ]

  connect() {
    console.log('swiper connected')

    

    // var gridSize = swiper.dataset.grid_size
    var gridSize = this.gridTarget.dataset.gridsize

    console.log(gridSize)
    
    var swiper = new Swiper(".mySwiper", {
      modules: [Navigation, Pagination],
      spaceBetween: 30,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        // when window width is >= 320px
        100: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        // when window width is >= 480px
        768: {
          slidesPerView: gridSize - 2,
          spaceBetween: 20
        },
        // when window width is >= 640px
        1024: {
          slidesPerView: gridSize - 1,
          spaceBetween: 30
        },
        1280: {
          slidesPerView: gridSize,
          spaceBetween: 30
        }
      }
    });

  }
}
