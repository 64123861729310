import { Controller } from "@hotwired/stimulus"
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

// Connects to data-controller="course-dates"
export default class extends Controller {

  connect() {

    console.log("course-dates connected")

    var swiper = new Swiper(".course_dates_carousel", {
      modules: [Navigation, Pagination, Autoplay],
      spaceBetween: 30,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      autoplay: {
        delay: 3000,
      },
      breakpoints: {
        // when window width is >= 320px
        640: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        // when window width is >= 480px
        768: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        // when window width is >= 640px
        1024: {
          slidesPerView: 4,
          spaceBetween: 30
        },
        1700: {
          slidesPerView: 6,
          spaceBetween: 30
        }
      }
    });
    
  }
}
