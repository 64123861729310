import { Controller } from "@hotwired/stimulus"
// import * as mobiscroll from "@mobiscroll/javascript"
var calendar
export default class extends Controller {
  static targets = ["startDate", "endDate", "datePaid"]
  static values = {
    guidedate: String
  }
  
  connect() {
    console.log("initialising calendar")

    mobiscroll.setOptions({
      theme: 'ios',
      themeVariant: 'light'
    });

    (function () {

      var formatDate = mobiscroll.util.datetime.formatDate;
      var currentEvent;
      var timer;
      var tooltip = document.getElementById('custom-event-tooltip-popup');
      // var urls = ['/course_dates/guaranteed.json', '/order_trip_courses/privates.json', '/meetings/calendar.json', '/mountain_hut_reservations/reservations.json']

      var header = document.getElementById('tooltip-event-header');
      var data = document.getElementById('tooltip-event-title');
      var participants = document.getElementById('participants');
      var guides = document.getElementById('guides');
      var huts = document.getElementById('huts');

      const calendarObject = document.getElementById('calendar')

      calendar = mobiscroll.eventcalendar(calendarObject, {
        view: {
          calendar: {
            endDay: 7,
            type: calendarObject.dataset.type,
            weekNumbers: true
          }
        },
        firstDay: 1,
        height: calendarObject.dataset.height,
        clickToCreate: false,
        dragToCreate: false,
        showEventTooltip: false,
        onPageLoading: function (event, inst) {
          inst._events = []

          console.log(event.firstDay)

          var year = event.firstDay.getFullYear(),
            month = event.firstDay.getMonth() + 1,
            day = event.firstDay.getDate();
          var urls = calendarObject.dataset.urls.split(' ')
          console.log(urls)

          loadData(urls, year, month, day, calendarObject.dataset.overlap, inst);
        },
        onEventHoverIn: function (args, inst) {
          timer = setTimeout(function () {
            mouseOver(args, inst)
          }, 500);
        },
        onEventHoverOut: function (args) {
          if (!timer) {
            timer = setTimeout(function () {
              tooltip.close();
            }, 200);
          }
        },
        onEventClick: function (data, inst) {
          console.log()
          window.location.href = data.event.url
        },
        // onCellClick: function(data, inst) {
        //   inst.setOptions({
        //     view: {
        //         agenda: { type: 'month' }
        //     }
        //   })
        // },
      });

      var tooltip = mobiscroll.popup('#custom-event-tooltip-popup', {
        display: 'anchored',
        touchUi: true,
        showOverlay: false,
        contentPadding: false,
        closeOnOverlayClick: false,
        width: 350,
        onInit: function () {
          tooltip.addEventListener('mouseenter', function (e) {
            if (timer) {
              clearTimeout(timer);
              timer = null;
            }
          });

          tooltip.addEventListener('mouseleave', function () {
            timer = setTimeout(function () {
              tooltip.close();
            }, 200);
          });
        }
      });


      function participantsList(list) {
        var tempList = '<table class="table-auto w-full">'
        for (const p in list) {
          tempList += '<tr class="text-sm"><td class="w-1/2"><a href="' + list[p].order + '">' + list[p].name + '</a></td><td class="w-1/2">' + list[p].mobile + '</td></tr>'
        }
        tempList += '</table>'
        return tempList
      }

      function guidesList(list) {
        var tempList = '<table class="table-auto w-full">'
        for (const p in list) {
          tempList += '<tr class="text-sm"><td class="w-1/2">' + list[p].name + '</td><td class="w-1/2">' + list[p].mobile + '</td></tr>'
        }
        tempList += '</table>'
        return tempList
      }

      function hutsList(list) {
        var tempList = '<table class="table-auto w-full">'
        for (const p in list) {
          tempList += '<tr class="text-sm"><td class="w-1/2">' + list[p].hut + '</td><td class="w-1/2">' + list[p].date + '</td></tr>'
        }
        tempList += '</table>'
        return tempList
      }

      function mouseOver(args, inst) {
        var event = args.event;
        var eventTime = formatDate('hh:mm A', new Date(event.start)) + ' - ' + formatDate('hh:mm A', new Date(event.end));
        var button = {};

        currentEvent = event;

        header.style.backgroundColor = event.color;
        data.innerHTML = event.title;

        participants.innerHTML = participantsList(event.participants)
        guides.innerHTML = guidesList(event.guides)
        huts.innerHTML = hutsList(event.huts)
        if (event.course_title !== undefined) {
          course_title.innerHTML = event.course_title;
        } else {
          course_title.innerHTML = ""
        }

        clearTimeout(timer);
        timer = null;

        tooltip.setOptions({ anchor: args.domEvent.target });
        tooltip.open();
      }

      function loadData(urls, year, month, day, period, inst) {
        urls.forEach(function (url) {
          var dateSent = year + '-' + month + '-' + day
          mobiscroll.util.http.getJson(url + '?d=' + dateSent + '&p=' + period, function (data) {

            for (var i = 0; i < data.length; i++) {
              var ev = {
                id: data[i].id,
                start: data[i].start,
                end: data[i].end || '',
                allDay: data[i].allDay,
                title: data[i].title,
                course_title: data[i].course_title,
                color: data[i].color,
                participants: data[i].participants,
                guides: data[i].guides,
                huts: data[i].huts,
                url: data[i].url,
                resource: data[i].resource
              };
              inst.addEvent(ev);
            }

            // mobiscroll.toast({
            //   message: 'New events loaded'
            // });

            console.log(inst._events)

          }, 'json');
        });
      }

    })();
  }

  changeDate(event) {
    console.log(event)
    var orderDate = event.target.dataset.guidedate
    calendar.navigate(new Date(orderDate));
  }

}
