import { Controller } from "@hotwired/stimulus"
import JustValidate from 'just-validate';

// Connects to data-controller="enquiry"
export default class extends Controller {
  static targets = ["theForm", "recaptcha"]

  connect() {
    console.log('enquiry loaded')

    console.log(this.recaptchaTarget)

    this.validateEnquiry()
  }

  validateEnquiry() {
    (() => {
      var form = this.theFormTarget
      var lang = form.dataset.lang

      const validation = new JustValidate(form, {
        errorFieldCssClass: 'is-invalid',
        errorLabelStyle: {
          marginTop: '0.25rem',
          fontSize: '10px',
          color: '#dc3545',
        },
        focusInvalidField: true,
        lockForm: true,
      },
        [
          {
            key: 'required',
            dict: {
              en: 'This field is required',
              fr: 'Champ obligatoire',
            }
          }
        ]
      );

      validation.setCurrentLocale(lang)

      validation
        .addField('#enquiry_first_name', [
          {
            rule: 'required',
            errorMessage: 'required'
          }
        ])
        .addField('#enquiry_last_name', [
          {
            rule: 'required',
            errorMessage: 'required'
          }
        ])
        .addField('#enquiry_email_address', [
          {
            rule: 'required',
            errorMessage: 'required'
          }
        ])
        .addField('#enquiry_phone', [
          {
            rule: 'required',
            errorMessage: 'required'
          }
        ])
        
        .onSuccess((event) => {
          console.log('Validation passes and form submitted', event);

          const recaptcha = this.recaptchaTarget

          grecaptcha.ready(function() {
            grecaptcha.execute('6LdVCyMiAAAAAPL03sKebfF-4q_Huvj_ETqDabbf', {action: 'submit'}).then(function(token) {
              console.log('token', token)
              console.log(recaptcha)
              recaptcha.value = token
              form.submit(token)
            });
          });
          
        })
    })();
  }
}
