import { Controller } from "@hotwired/stimulus"
import JustValidate from 'just-validate';

// Connects to data-controller="payment"
export default class extends Controller {
  static targets = ["theForm"]

  connect() {
    console.log("payment loaded")
    this.validatePayment()
  }

  validatePayment() {
    (() => {
      var form = this.theFormTarget
      var lang = form.dataset.lang

      const validation = new JustValidate(form, {
        errorFieldCssClass: 'is-invalid',
        errorLabelStyle: {
          marginTop: '0.25rem',
          fontSize: '10px',
          color: '#dc3545',
        },
        focusInvalidField: true,
        lockForm: true,
      },
        [
          {
            key: 'required',
            dict: {
              en: 'This field is required',
              fr: 'Champ obligatoire',
            }
          }
        ]
      );

      validation.setCurrentLocale(lang)

      validation
        .addField('#payment_first_name', [
          {
            rule: 'required',
            errorMessage: 'required'
          }
        ])
        .addField('#payment_last_name', [
          {
            rule: 'required',
            errorMessage: 'required'
          }
        ])
        .addField('#payment_email_address', [
          {
            rule: 'required',
            errorMessage: 'required'
          }
        ])
        .addField('#payment_payment_amount', [
          {
            rule: 'required',
            errorMessage: 'required'
          }
        ])
        .addField('#payment_billing_address_street', [
          {
            rule: 'required',
            errorMessage: 'required'
          }
        ])
        .addField('#payment_billing_address_city', [
          {
            rule: 'required',
            errorMessage: 'required'
          }
        ])
        .addField('#payment_billing_address_zip_code', [
          {
            rule: 'required',
            errorMessage: 'required'
          }
        ])
        .addField('#payment_billing_address_country', [
          {
            rule: 'required',
            errorMessage: 'required'
          }
        ])
        .onSuccess((event) => {
          console.log('Validation passes and form submitted', event);
          form.submit()
        })
    })();
  }

}
