import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="order-guide"
export default class extends Controller {
  static values = { startDate: String, endDate: String, datePaid: String }
  static targets = ["dates", "startDate", "endDate", "datePaid"]

  connect() {
    console.log("order guides loaded")

    var startDate = this.startDateTarget
    var endDate = this.endDateTarget
    var datePaid = this.datePaidTarget

    mobiscroll.datepicker(this.datesTarget, {
      controls: ['calendar'],
      select: 'range',
      dateFormat: 'MMMM DD, YYYY', 
      touchUi: true,
      showWeekNumbers: false,
      rangeHighlight: true,
      showRangeLabels: true,
      rangeStartLabel: 'First day',
      rangeEndLabel: 'Last day',
      display: 'anchored',
      showOnClick: true,
      showOnFocus: true,
      firstDay: 1,
      onInit: function (event, inst) {
        if (!startDate) {
          console.log("setting order dates as global")
          inst.setVal([startDate.value, endDate.value]);
        } else {
          inst.setVal([startDate.value, endDate.value]);
        }
      },
      onChange: function (event, inst) {
        console.log(startDate)
        startDate.value = event.value[0]
        endDate.value = event.value[1]
      }
    });

    mobiscroll.datepicker('#order_guide_date_paid', {
      controls: ['calendar'],
      dateFormat: 'MMMM DD, YYYY', 
      touchUi: true,
      showWeekNumbers: false,
      rangeHighlight: true,
      showRangeLabels: true,
      display: 'anchored',
      showOnClick: true,
      showOnFocus: true,
      onInit: function (event, inst) {
        if (!datePaid) {
          console.log("setting order dates as global")
          inst.setVal(datePaid.value);
        } else {
          inst.setVal(datePaid.value);
        }
      },
    });
  }
}
