import { Controller } from "@hotwired/stimulus"
import { useHover } from 'stimulus-use'

// Connects to data-controller="navigation"
export default class extends Controller {
  static targets = ["navHamburger", "navbar", "subnav"]

  connect() {
    console.log('navigation loaded')
    useHover(this, { element: this.subnavTarget });
  }

  clickedNav() {
    console.log('nav clicked')
    var x =  document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }

  showMenu(e) {
    this.removeTranslate()

    var menu = document.getElementById(e.target.dataset.name)
    menu.classList.toggle('translate-y-full')
  }

  removeTranslate() {
    var menus = Array.from(document.getElementsByClassName('translate-y-full'))

    menus.forEach(box => {
      box.classList.remove('translate-y-full')
    })
  }

  mouseEnter() {
    console.log('mouse enter')
  }

  mouseLeave() {
    console.log('mouse leave')
    this.removeTranslate()
  }

}
